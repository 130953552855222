.container {
    width: 90%;
    max-width: 960px;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
	padding: $spacing;
    }
}

main {
    border: 4px double $light-grey;
    border-width: 4px 0;
}

header {
    &#masthead {
	padding: $spacing-lg 0;

	@media screen and (min-width: 768px) {
	    display: flex;
	    flex-direction: row;
	    align-items: center;

	    h1 {
		flex: 1;
	    }
	}
    }
}

article {
    padding: $spacing-lg 0;
    border-bottom: 1px solid $light-grey;

    &:last-child {
	border-width: 0;
    }

    figure {
	padding: $spacing 0;

	figcaption {
	    padding: 0 $spacing;
	    font-style: italic;
	    font-size: 18px;
	}

	@media screen and (min-width: 768px) {
	    display: flex;
	    flex-direction: row;
	    align-items: center;

	    figcaption {
		flex: 1;
	    }
	}
    }
}

footer {
    font-size: 14px;
}
