$white: #fff;
$black: #000;
$grey: darken($white, 50%);
$dark-grey: darken($white, 80%);
$light-grey: darken($white, 20%);
$lightest-grey: darken($white, 10%);
$almost-white: darken($white, 2%);

$dull-gold: #B1A586;
$text-grey: rgb(78, 84, 87);
$sober-brown: #414141;

$map-red: #72333D;
$map-blue: #217484;

$link-colour: $dull-gold;
$link-hover: $sober-brown;
$text-colour: $dark-grey;


$spacing: 12px;
$spacing-sm: 8px;
$spacing-xs: 4px;
$spacing-lg: 16px;
$spacing-xl: 24px;
$spacing-xxl: 36px;
$spacing-xxxl: 72px;

$breakpoint-sm: 320px;
$breakpoint-md: 720px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1440px;

$max-content-width: 960px;

