/*!
Title: DR T Konkani Book CSS
Author: Prathamesh Pai
Author URI: http://transducent.com/
Version: 1.0.0
*/

@import 'fonts';
@import 'vars';
@import 'reset';
@import 'base';
@import 'layout';
