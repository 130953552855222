body {
    font-family: $font-main;
    font-size: $font-size;
    line-height: $leading;

    color: $text-colour;
    background-color: $white;
}

p {
    margin: $spacing 0;
}

h1, h2, h3 {
    font-weight: normal;
    color: $black;
}


h1 {
    font-size: 48px;
    line-height: 1;
}

h2 {
    font-size: 32px;
}

strong {
    font-weight: bold;
}

em {
    font-style: italic;
}
